
<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Daily Purchases</b-card-title>
      <b-card-text class="font-small-2 mr-15 mb-0">
     
      </b-card-text>
    </b-card-header>

    <b-card-body class="statistics-body">
      <b-row>
        <b-breadcrumb>
          <b-breadcrumb-item v-if="locationBreadcrumbs.length > 0">
            <a href="#" @click.prevent="clearBreadcrumbs()">
              <feather-icon icon="HomeIcon" class="ml-1"/>
            </a>
          </b-breadcrumb-item>

          <b-breadcrumb-item
            v-for="(breadcrumb, index) in locationBreadcrumbs"
            :key="index"
            :active="index === locationBreadcrumbs.length - 1"
          >
            <a
              v-if="index !== locationBreadcrumbs.length - 1"
              href="#"
              @click.prevent="onBreadcrumbClick(breadcrumb.id, index + 1)"
            >
              {{ breadcrumb.text }}
            </a>
            <span v-else>{{ breadcrumb.text }}</span>
          </b-breadcrumb-item>
        </b-breadcrumb>
        <b-col cols="4">
          <b-form-group label-for="Location" label="Location">
            <b-form-select
              size="sm"
              v-model="selectedLocationId"
              @change="onChange($event)"
            >
              <b-form-select-option :value="null">-- Select --</b-form-select-option>
              <b-form-select-option v-for="purchase in purchases.purchases" :key="purchase.id" :value="purchase">
                {{ purchase.name.toUpperCase() }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group label-for="Day Of" label="Day Of">
            <b-form-datepicker
                size="sm"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
  <div class="d-flex justify-content-end mt-10" style="margin-top: 24px">
    <b-button
      variant="outline-success"
      size="sm"
      @click="generateReport()"
    >
      <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
      <span class="text-nowrap">Download</span>
      <feather-icon icon="FileTextIcon" class="ml-1"/>
    </b-button>
  </div>
</b-col>

      </b-row>

      <b-row v-if="loading">
        <b-col cols="12" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
        </b-col>
      </b-row>
      <b-row v-else>
  <b-col cols="12">
    <div class="table-responsive" style="max-height: 500px; overflow-y: auto;">
      <table class="table table-striped table-bordered">
        <thead>
          <tr class="text-center">
            <th rowspan="2"></th>
            <th colspan="5">Today</th>
            <th colspan="5">Todate</th>
            <th colspan="2">Target</th>
          </tr>
          <tr class="text-center">
            <th>Purchases Kgs</th>
            <th>Value</th>
            <th>Loan Recovered</th>
            <th>Net Pay</th>
            <th>Average Price</th>
            <th>Purchases Kgs</th>
            <th>Value</th>
            <th>Loan Recovered</th>
            <th>Net Pay</th>
            <th>Average Price</th>
            <th>Target Kgs</th>
            <th>% Achieved</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in purchases.purchases" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.today.weight.toLocaleString() }}</td>
            <td>{{ item.today.value.toLocaleString() }}</td>
            <td>{{ item.today.deductions.toLocaleString() }}</td>
            <!-- <td>{{ item.today.value.toLocaleString() }}</td> -->
            <td>{{ (item.today.value - item.today.deductions).toLocaleString() }}</td>
            <td v-if="item.today.weight > 0">{{ (item.today.value / item.today.weight).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}</td>
            <td v-if="item.today.weight == 0">0</td>
            <td>{{ item.todate.weight.toLocaleString() }}</td>
            <td>{{ item.todate.value.toLocaleString() }}</td>
            <td>{{ item.todate.deductions.toLocaleString() }}</td>
            <td>{{ item.todate.value.toLocaleString() }}</td>
            <td v-if="item.todate.weight > 0">{{ (item.todate.value / item.todate.weight).toLocaleString(undefined, { maximumFractionDigits: 2 }) }}</td>
            <td v-if="item.todate.weight == 0">0</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-col>
</b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import { ref, onUnmounted, onMounted, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
  BCardTitle,
      BCardText,
  BForm, BFormGroup,
  BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
  BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker, BBreadcrumb, BBreadcrumbItem
  } from 'bootstrap-vue'
import useLineSeriesStoreModule from '@/views/dashboard/ecommerce/useLineSeriesStoreModule'
import store from '@/store'

import vSelect from 'vue-select'

import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import VueApexCharts from 'vue-apexcharts';
import MultiSelect from 'primevue/multiselect';

export default {
  components: {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BCardTitle,
      BCardText,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
    BSpinner, BBreadcrumb, BBreadcrumbItem,
    ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker, VueApexCharts, MultiSelect
  },
  setup() {
    const locations = ref([])
    const selectedLocationName = ref(null);
    const locationBreadcrumbs = ref([]);
    const purchases = ref([]);
    const selectedLocationId = ref(null);
    const loading = ref(true);
    const level = ref();
    const saving = ref(false)
    const filterValue = ref({
            startDate: null,
            endDate: null,
        })

    const CROMIS_STORE_MODULE_NAME = 'cromis-sale_trend'
    // Register module
    if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, useLineSeriesStoreModule)

    onMounted(async () => {
      await store.dispatch('cromis-sale_trend/dailData')
        .then(response => {
          purchases.value= response.data.data
          level.value = response.data.data.level
          console.log(level.value)
          loading.value = false;
        })
        .catch(error => {
          console.log('got error', error)
          loading.value = false;
        })
    });

    const onChange = async (event) => {
      loading.value = true;
     
      const location = purchases.value.purchases.find(loc => loc.id === event.id);
      console.log(purchases.value.level)
      selectedLocationName.value = location ? location.name : null;
      if (location) {
        locationBreadcrumbs.value.push({ text: location.name, id: location.id });
      }
      console.log(level.value)
      let payload = {
        'location_id': event.id,
        "level": level.value
      }
      console.log(payload)
      await store.dispatch('cromis-sale_trend/dailData', payload)
        .then(response => {
          purchases.value= response.data.data
          level.value = response.data.data.level
          console.log(level.value)
          console.log(response.data)
          loading.value = false;
        })
        .catch(error => {
          console.log('got error', error)
          loading.value = false;
        })
    }

    const onBreadcrumbClick = async (id, index) => {
      // Remove breadcrumbs after the clicked one
      locationBreadcrumbs.value = locationBreadcrumbs.value.slice(0, index + 1);
      selectedLocationId.value = id;
      await onChange(id);
    };

    const clearBreadcrumbs = async () => {
      loading.value = true;
      locationBreadcrumbs.value = [];
      selectedLocationId.value = null; 

      await store.dispatch('cromis-sale_trend/dailData')
        .then(response => {
          purchases.value= response.data.data
          console.log(purchases.value)
          loading.value = false;
        })
        .catch(error => {
          console.log('got error', error)
          loading.value = false;
        })
    };


    const generateReport = async () => {

                filterValue.value = {
                    startDate: null,
                    endDate: null,
                }
              
            }

    return {
      purchases,
      locations,
      onBreadcrumbClick,
      clearBreadcrumbs,
      locationBreadcrumbs,
      selectedLocationName,
      locationBreadcrumbs,
      selectedLocationId,
      onChange,
      loading,
      generateReport,
      filterValue,
      saving
    }
  }
}
</script>

<style scoped>
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
</style>
