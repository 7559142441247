var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',[_vm._v("Filter Location")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"labe-for":"region_id","label":"Region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"region_id","name":"region_id","size":"md"},on:{"change":function($event){return _vm.changeRegion(_vm.filter.region_id)}},model:{value:(_vm.filter.region_id),callback:function ($$v) {_vm.$set(_vm.filter, "region_id", $$v)},expression:"filter.region_id"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("All")]),_vm._l((_vm.locations),function(region){return _c('b-form-select-option',{key:region.id,attrs:{"value":region.id}},[_vm._v(" "+_vm._s(region.name.toUpperCase())+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serverErrors && _vm.serverErrors.region_id)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.serverErrors.region_id[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"labe-for":"location_id","label":"Zone"}},[_c('validation-provider',{attrs:{"name":"Area","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"location_id","name":"location_id","size":"md"},on:{"change":function($event){return _vm.getAreaMarket(_vm.filter.location_id)}},model:{value:(_vm.filter.location_id),callback:function ($$v) {_vm.$set(_vm.filter, "location_id", $$v)},expression:"filter.location_id"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select...")]),_vm._l((_vm.locId.children),function(location){return _c('b-form-select-option',{key:location.id,attrs:{"value":location.id}},[_vm._v(" "+_vm._s(location.name.toUpperCase())+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serverErrors && _vm.serverErrors.location_id)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.serverErrors.location_id[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"labe-for":"market_id","label":"CPP"}},[_c('validation-provider',{attrs:{"name":"CPP","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"market_id","name":"market_id","size":"md"},on:{"change":function($event){return _vm.getAreaMarketSale(_vm.filter.market_id)}},model:{value:(_vm.filter.market_id),callback:function ($$v) {_vm.$set(_vm.filter, "market_id", $$v)},expression:"filter.market_id"}},[_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("Select...")]),_vm._l((_vm.markets),function(market){return _c('b-form-select-option',{key:market.id,attrs:{"value":market.id}},[_vm._v(" "+_vm._s(market.name.toUpperCase())+" ")])})],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serverErrors && _vm.serverErrors.market_id)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.serverErrors.market_id[0]))]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',[_vm._v(" From "),_c('small',{staticClass:"text-danger"})]),_c('validation-provider',{attrs:{"name":"StartDate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"startDate","name":"startDate","state":errors.length > 0 ? false : null,"size":"md","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"en"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serverErrors && _vm.serverErrors.startDate)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.serverErrors.startDate[0]))]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',[_vm._v(" To "),_c('small',{staticClass:"text-danger"})]),_c('validation-provider',{attrs:{"name":"End Date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"endDate","name":"endDate","state":errors.length > 0 ? false : null,"size":"md","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' },"locale":"en"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.serverErrors && _vm.serverErrors.endDate)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.serverErrors.endDate[0]))]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"mt-10",staticStyle:{"margin-top":"24px"}},[_c('b-button',{attrs:{"variant":"outline-success","size":"md"},on:{"click":function($event){return _vm.generateReport()}}},[(_vm.saving)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":"","label":"Small Spinner"}}):_vm._e(),_c('span',{staticClass:"text-nowrap"},[_vm._v("Generate")]),_c('feather-icon',{staticClass:"ml-1",attrs:{"icon":"DownloadCloudIcon"}})],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }