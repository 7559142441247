import { render, staticRenderFns } from "./ShortHaul.vue?vue&type=template&id=2019821a&scoped=true"
import script from "./ShortHaul.vue?vue&type=script&lang=js"
export * from "./ShortHaul.vue?vue&type=script&lang=js"
import style0 from "./ShortHaul.vue?vue&type=style&index=0&id=2019821a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2019821a",
  null
  
)

export default component.exports